import "./mainbody.css";
import React from "react";
import { useState, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { Scrollbars } from "react-custom-scrollbars";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import axios from "axios";
import {
  Drawer,
  Form,
  Button,
  Schema,
  Input,
  DateRangePicker,
  InputPicker,
  SelectPicker,
  Uploader,
  CheckPicker,
  DatePicker,
  TagPicker,
  Loader,
  Placeholder,
} from "rsuite";
import { getBaseUrl, getToken } from "../../../../http";

const apiBaseUrl = getBaseUrl();
const token = getToken();
const Field = React.forwardRef((props, ref) => {
  const {
    data,
    name,
    message,
    label,
    accepter,
    error,
    multiple,
    onChange,
    ...rest
  } = props;
  return (
    <Form.Group
      controlId={`${name}-10`}
      ref={ref}
      className={error ? "has-error" : ""}
    >
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control
        data={data}
        name={name}
        accepter={accepter}
        errorMessage={false}
        onChange={onChange}
      />
    </Form.Group>
  );
});

const { ArrayType, StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  pname: StringType().isRequired("Schedule name required"),
  psheduledate: ArrayType().isRequired(
    "Please select the shedule date and time "
  ),
  pstype: NumberType().isRequired("Signage type required"),
  pcustomer: NumberType().isRequired("Customer required"),
  pinstteam: ArrayType().isRequired("Please select the installation team "),
  pvehicle: NumberType().isRequired("Vehicle type required"),
  plocation: StringType().isRequired("Location name required"),
  psalesperson: NumberType().isRequired("Sales Person required"),
  psalescordin: NumberType().isRequired("Sales Coordinator required"),
  pequipment: ArrayType().isRequired("Please select the Equipments"),
});

const errorStyles = (errorVisible) => {
  return {
    display: errorVisible ? "block" : "block",
    color: "red",
    marginBottom: 15,
  };
};

const defaultFormValue = {
  checkInDate: new Date(),
  checkOutDate: new Date(),
  asset: [],
  accessories: [],
  preparedBy: "",
  licences: [],
  comments:""
};

const Mainbody = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [assetData, setAssetData] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const [checkedOutData, setCheckedOutDate] = useState([]);
  const [checkedOutAccessories, setcheckedOutAccessories] = useState([]);
  const [checkedOutLicenses, setCheckedOutLicenses] = useState([]);
  const [accessoriesData, setAccessoriesData] = useState([]);
  const [licencesData, setLicencesData] = useState([]);
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState(defaultFormValue);
  const [assetLoader, setAssetLoader] = useState(false);
  const attachementuploader = React.useRef();
  const divRef = React.useRef();
  const getUserData = async () => {
    setAssetLoader(true);
    var configAsset = {
      method: "get",
      url: apiBaseUrl + "users/" + id + "/assets",
      headers: {
        Authorization: token,
      },
    };
    const resUserAsset = await axios(configAsset);
    let obj = [];
    for await (let data of resUserAsset.data.rows) {
      data.checkouted = true;
      obj.push(data);
    }
    setCheckedOutDate(obj);

    var configLicences = {
      method: "get",
      url: apiBaseUrl + "users/" + id + "/licenses", //apiBaseUrl + "users/" + id + "/accessories",
      headers: {
        Authorization: token,
      },
    };
    const resCheckedOutLicences = await axios(configLicences);
    let objLic = [];
    for await (let data of resCheckedOutLicences.data.rows) {
      data.checkouted = true;
      objLic.push(data);
    }
    setCheckedOutLicenses(objLic);
    //console.log(checkedOutLicenses, "licences");

    var configAccessories = {
      method: "get",
      url: apiBaseUrl + "users/" + id + "/accessories",
      headers: {
        Authorization: token,
      },
    };
    const resCheckedOutAccessories = await axios(configAccessories);
    let obj1 = [];
    for await (let data of resCheckedOutAccessories.data.rows) {
      data.checkouted = true;
      obj1.push(data);
    }
    setcheckedOutAccessories(obj1);
    //console.log(checkedOutAccessories,'checkedOutAccessories')

    var config = {
      method: "get",
      url: apiBaseUrl + "users/" + id,
      headers: {
        Authorization: token,
      },
    };
    const res = await axios(config);
    setUserData(res.data);
    let locationId=res.data.location==null?0:res.data.location.id;
    if(locationId>0)
    {
    var config1 = {
      method: "get",
      url: apiBaseUrl + "locations/" + locationId,
      headers: {
        Authorization: token,
      },
    };
    const resComp = await axios(config1);
  //  console.log(resComp.data,'resComp.data');
    setCompanyData(resComp.data);
  }
    var config2 = {
      method: "get",
      url: apiBaseUrl + "hardware",
      headers: {
        Authorization: token,
      },
    };
    const resAsset = await axios(config2);
    console.log(resAsset.data.rows, "resAsset.data.rows");
    let asset = resAsset.data.rows.filter((x) => x.assigned_to == null);
    //  console.log(asset,'asset');
    let assetObj = [];
    for await (let item of asset) {
      let obj = { label: item.name, value: item.id, asset: item };
      assetObj.push(obj);
    }
    assetObj.map((item, index) => ({
      label: item.name,
      value: item.id,
      asset: item,
    }));
    setAssetData(assetObj);

    var config2 = {
      method: "get",
      url: apiBaseUrl + "licenses",
      headers: {
        Authorization: token,
      },
    };
    const resLicences = await axios(config2);
    //console.log(resLicences.data.rows, "resLicences.data.rows");
    let licence = resLicences.data.rows.filter((x) => x.assigned_to == null);
    //  console.log(asset,'asset');
    let licenceObj = [];
    for await (let item of licence) {
      let obj = { label: item.name, value: item.id, licence: item };
      licenceObj.push(obj);
    }
    licenceObj.map((item, index) => ({
      label: item.name,
      value: item.id,
      licence: item,
    }));
    setLicencesData(licenceObj);

    var configAccessories = {
      method: "get",
      url: apiBaseUrl + "accessories",
      headers: {
        Authorization: token,
      },
    };
    const resAccessories = await axios(configAccessories);
   // console.log(resAccessories.data.rows, "resAccessories.data.rows");
    let accessories = resAccessories.data.rows.filter(
      (x) => x.remaining_qty > 0
    );
    //  console.log(asset,'asset');
    let accessoriesObj = [];
    for await (let item of accessories) {
      let obj = { label: item.name, value: item.id, accessories: item };
      accessoriesObj.push(obj);
    }
    accessoriesObj.map((item, index) => ({
      label: item.name,
      value: item.id,
      accessories: item,
    }));
    setAccessoriesData(accessoriesObj);
    setAssetLoader(false);
  };

  const assetSelect = (value) => {
    let temp_checkedOutData = [];
    let temp = checkedOutData.filter((x) => x.checkouted == true);
    temp_checkedOutData = temp;

    setCheckedOutDate([]);
    if (value != null) {
      for (let fv of value) {
        debugger;
        let selectedAsst = [];
        selectedAsst = assetData.find((x) => x.value == fv);
        let item = selectedAsst.asset;
        item.checkouted = false;
        temp_checkedOutData.push(item);
      }
    }

    // let selected=assetData.find(x=>x.value==value);
    // let item=selected.asset;
    // item.checkouted=false;
    // temp_checkedOutData.push(item);
    setCheckedOutDate(temp_checkedOutData);
  };

  const accessoriesSelect = (value) => {
    debugger;
    let temp_checkedOutData = [];
    let temp = checkedOutAccessories.filter((x) => x.checkouted == true);
    temp_checkedOutData = temp;
    setcheckedOutAccessories([]);
    if (value != null) {
      for (let fv of value) {
        debugger;
        let selectedAccessories = [];
        selectedAccessories = accessoriesData.find((x) => x.value == fv);
        let item = selectedAccessories.accessories;
        item.checkouted = false;
        temp_checkedOutData.push(item);
      }
    }
    //  let selected=  accessoriesData.find(x=>x.value==value);
    //console.log(selectedAsst,'selectedAsst');
    setcheckedOutAccessories(temp_checkedOutData);
  };

  const licencesSelect = (value) => {
    let temp_checkedOutData = [];
    let temp = checkedOutLicenses.filter((x) => x.checkouted == true);
    temp_checkedOutData = temp;

    setCheckedOutLicenses([]);
    if (value != null) {
      for (let fv of value) {
        debugger;
        let selectedLic = [];
        selectedLic = licencesData.find((x) => x.value == fv);

        let item = selectedLic.licence;
        item.checkouted = false;
        temp_checkedOutData.push(item);
      }
    }

    // let selected=assetData.find(x=>x.value==value);
    // let item=selected.asset;
    // item.checkouted=false;
    // temp_checkedOutData.push(item);
    setCheckedOutLicenses(temp_checkedOutData);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const checkOutAssets = async () => {
    try {
      debugger;
      if (formValue.asset != null) {
        setAssetLoader(true);
        for await (let sv of formValue.asset) {
          let obj = checkedOutData.find((x) => x.id == sv);
          var FormData = require("form-data");
          var data = new FormData();
          data.append("status_id", 2);
          data.append("checkout_to_type", "user");
          data.append("assigned_user", id);
          // data.append("assigned_asset", id);
          //   data.append("assigned_location","");
          data.append("expected_checkin", "2023-06-30"); //formValue.checkInDate);
          data.append("checkout_at", "2023-06-10"); //formValue.checkOutDate);
          data.append("name", "HP Laptop 16"); //(obj.name).trim());
          data.append("note", "Test");
       //   console.log(data, " form data");
          var config = {
            method: "post",
            url: apiBaseUrl + "hardware/" + sv + "/checkout",
            headers: {
              Authorization: token,
            },
            data: data,
          };

          let checkoutResponse = await axios(config);
         // console.log(checkoutResponse, "checkout response");
        }
        setAssetLoader(false);
        setFormValue(defaultFormValue);
        toast.success("Assets successfull checked out.", { duration: 6000 });
      }
    } catch (e) {
      setAssetLoader(false);
      toast.error("Server Error.", { duration: 6000 });
    //  console.log(e, "error");
    }
  };
  const checkOutLicences = async () => {
    try {
      debugger;
      if (formValue.licences != null) {
        setAssetLoader(true);
        for await (let sv of formValue.licences) {
          var config = {
            method: "get",
            url: apiBaseUrl + "licenses/" + sv + "/seats",
            headers: {
              Authorization: token,
            },
          };

          let availSeatResponse = await axios(config);
          let seatObj = availSeatResponse.data.rows.find(
            (x) => x.assigned_user == null
          );
      //   console.log(seatObj, "SeatObj");
          if (seatObj != null) {
            var data = {
              assigned_to: parseInt(id),
            };
            var config = {
              method: "put",
              url: apiBaseUrl + "licenses/" + sv + "/seats/" + seatObj.id,
              headers: {
                Authorization: token,
              },
              data: data,
            };

            let checkoutResponse = await axios(config);
           // console.log(checkoutResponse, "checkout response");
          }
        }
        setAssetLoader(false);
        setFormValue(defaultFormValue);
        toast.success("Assets successfull checked out.", { duration: 6000 });
      }
    } catch (e) {
      setAssetLoader(false);
      toast.error("Server Error.", { duration: 6000 });
     // console.log(e, "error");
    }
  };
  const checkOutAccessories = async () => {
    try {
      debugger;
      if (formValue.accessories != null) {
        setAssetLoader(true);
        for await (let sv of formValue.accessories) {
          let obj = checkedOutData.find((x) => x.id == sv);
          var FormData = require("form-data");
          var data = new FormData();

          data.append("assigned_to", id);
          data.append("note", "");

          var config = {
            method: "post",
            url: apiBaseUrl + "accessories/" + sv + "/checkout",
            headers: {
              Authorization: token,
            },
            data: data,
          };

          let checkoutResponse = await axios(config);
         // console.log(checkoutResponse, "checkout response");
        }
        setAssetLoader(false);
        setFormValue(defaultFormValue);
        toast.success("Accesssories successflly checked out.", {
          duration: 6000,
        });
      }
    } catch (e) {
      setAssetLoader(false);
      toast.error("Server Error.", { duration: 6000 });
      //console.log(e, "error");
    }
  };

  const assetCheckOutLoader = () => {
    if (assetLoader) {
      return (
        <div>
          <Loader backdrop content="loading..." vertical />
        </div>
      );
    } else {
      return "";
    }
  };
  // Create and append the iframe to the body

  const Print = () => {
  
    // console.log(formValue.comments,formValue.preparedBy);

    if (formValue.preparedBy != null && formValue.preparedBy != "") {
      let originalContents = document.body.innerHTML;
      console.log(originalContents,'originalContents');
      document.getElementById("licenceElement").style.display = "none";
      document.getElementById("assetElement").style.display = "none";
      document.getElementById("accessoryElement").style.display = "none";
      let printContents = document.getElementById("print_div").innerHTML;

      // Replace the body content with the content to be printed
      document.body.innerHTML = printContents;
      window.print();

      // Restore the original content after printing is finished
      document.body.innerHTML = originalContents;

      // Clear the printContents and originalContents variables
      printContents = null;
      originalContents = null;
      window.location.reload(false);
    } else {
      toast.error("Please fillout preparedby .", { duration: 6000 });
    }
  };

  return (
    <main className="py-6 bg-surface-secondary">
      <div className="container-fluid">
        <div className="row" id="print_div">
          <Form ref={formRef} formValue={formValue} onChange={setFormValue}>
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              id="sheet0"
              className="sheet0 gridlines"
            >
              <tbody>
                <tr className="row0">
                  <td className="column0 style23 null style25" colSpan="4">
                    <div>
                      <img src={companyData.image} border="0" />
                    </div>
                  </td>
                  <td className="column4 style32 s style34" colSpan="8">
                    IT Asset Form
                  </td>
                </tr>
                <tr className="row4">
                  <td className="column0 style39 s style40" colSpan="4">
                    &nbsp;&nbsp;Receiving Custodian Name
                  </td>
                  <td className="column4 style41 s style43" colSpan="3">
                    Receiving Custodian Emp No.
                  </td>
                  <td className="column7 style41 s style43" colSpan="4">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Department
                  </td>
                  <td className="column11 style7 s">Date</td>
                </tr>
                <tr className="row5">
                  <td className="column0 style99 s style101" colSpan="4">
                    {userData.first_name}
                  </td>
                  <td className="column4 style44 null style46" colSpan="3">
                    {userData.employee_num}
                  </td>
                  <td className="column7 style102 s style104" colSpan="4">
                    {userData.department != null
                      ? userData.department.name
                      : ""}
                  </td>
                  <td className="column11 style105 n">
                    {" "}
                    <Field
                      name="checkOutDate"
                      label=""
                      accepter={DatePicker}
                      data={formValue.checkOutDate}
                    />
                  </td>
                </tr>
                <tr className="row6">
                  <td className="column0 style52 s style53" colSpan="2">
                    Transfer Prepared By
                  </td>
                  <td className="column2 style54 s style53" colSpan="2">
                    Date Prepared{" "}
                  </td>
                  <td className="column4 style41 null style56" colSpan="8"></td>
                </tr>
                <tr className="row7">
                  <td className="column0 style106 s style107" colSpan="2">
                    <Field name="preparedBy" label="" accepter={Input} />
                  </td>
                  <td className="column2 style108 n style104" colSpan="2">
                    {todayDate.getDate()}-{todayDate.getMonth()}-
                    {todayDate.getFullYear()}
                  </td>
                  <td className="column4 style58 null style60" colSpan="8">
                    <span id="assetElement">
                      <Field
                        label=""
                        name="asset"
                        accepter={TagPicker}
                        data={assetData}
                        onChange={assetSelect}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-warning"
                        onClick={checkOutAssets}
                      >
                        Check Out
                      </button>
                      {assetCheckOutLoader()}
                    </span>
                  </td>
                </tr>
                <tr className="row8">
                  <td className="column0 style47 s style49" colSpan="12">
                    Assets
                  </td>
                </tr>
                <tr className="row9">
                  <td className="column0 style8 s">S.No</td>
                  <td className="column1 style50 s style61" colSpan="2">
                    Asset Tag
                  </td>
                  <td className="column3 style88 s style89" colSpan="2">
                    Type
                  </td>
                  <td className="column5 style50 s style61" colSpan="2">
                    Category
                  </td>
                  <td className="column7 style5 s">Model</td>
                  <td className="column8 style86 s style87" colSpan="2">
                    Serial
                  </td>
                  <td className="column10 style50 s style51" colSpan="2">
                    MAC Address
                  </td>
                </tr>
                {checkedOutData.map((check, b) => (
                  <tr className="row10" key={b}>
                    <td className="column0 style109 n">{b + 1}</td>
                    <td className="column1 style110 null style111" colSpan="2">
                      {check.asset_tag}
                    </td>
                    <td className="column3 style110 null style111" colSpan="2">
                      -
                    </td>
                    <td className="column5 style110 null style111" colSpan="2">
                      {check.category != null ? check.category.name : ""}
                    </td>
                    <td className="column7 style112 null">
                      {check.model.name}
                    </td>
                    <td className="column8 style110 null style111" colSpan="2">
                      {check.serial}
                    </td>
                    <td className="column10 style110 null style113" colSpan="2">
                      {check.custom_fields != ""
                        ? check.custom_fields.MAC_Address.value
                        : ""}
                    </td>
                  </tr>
                ))}

                <tr className="row19">
                  <td className="column0 style47 s style96" colSpan="6">
                    Software
                  </td>
                  <td className="column4 style58 null style60" colSpan="6">
                    <span id="licenceElement">
                      <Field
                        label=""
                        name="licences"
                        accepter={TagPicker}
                        data={licencesData}
                        onChange={licencesSelect}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-warning"
                        onClick={checkOutLicences}
                      >
                        Check Out
                      </button>
                      {assetCheckOutLoader()}
                    </span>
                  </td>
                </tr>
                <tr className="row20">
                  <td className="column0 style8 s">S.No</td>
                  <td className="column1 style50 s style93" colSpan="4">
                    Software Name
                  </td>
                  <td className="column5 style94 s style89" colSpan="4">
                    Username
                  </td>
                  <td className="column9 style50 s style51" colSpan="3">
                    Product Key
                  </td>
                </tr>
                {checkedOutLicenses.map((check, b) => (
                  <tr className="row21" key={b}>
                    <td className="column0 style114 n">{b + 1}</td>
                    <td className="column1 style102 s style104" colSpan="4">
                      {check.name}
                    </td>
                    <td className="column5 style121 s style121" colSpan="4">
                      {check.license_email}
                    </td>
                    <td className="column9 style118 null style119" colSpan="3">
                      {check.product_key}
                    </td>
                  </tr>
                ))}
                <tr className="row26">
                  <td className="column0 style47 s style6" colSpan="6">
                    Accessories
                  </td>
                  <td className="column0 style47 s style96" colSpan="6">
                    <span id="accessoryElement">
                    <Field
                      label=""
                      name="accessories"
                      accepter={TagPicker}
                      data={accessoriesData}
                      onChange={accessoriesSelect}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-warning"
                      onClick={checkOutAccessories}
                    >
                      Check Out
                    </button>
                    </span>
                  </td>
                </tr>

                <tr className="row27">
                  <td className="column0 style8 s">S.No</td>
                  <td className="column1 style50 s style93" colSpan="4">
                    Asset Tag
                  </td>
                  <td className="column5 style94 s style89" colSpan="4">
                    Name
                  </td>
                  <td className="column9 style50 s style51" colSpan="3"></td>
                </tr>
                {checkedOutAccessories.map((accessories, b) => (
                  <tr className="row28" key={b}>
                    <td className="column0 style44 null">{b + 1}</td>
                    <td className="column1 style44 null style46" colSpan="4">
                      {accessories.model_number}
                    </td>
                    <td className="column5 style44 null style45" colSpan="4">
                      {accessories.name}
                    </td>
                    <td className="column9 style97 null style98" colSpan="3">
                      {}
                    </td>
                  </tr>
                ))}

                <tr className="row35">
                  <td className="column0 style90 s style92" colSpan="12">
                    Comment:
                  </td>
                </tr>
                <tr className="row36">
                  <td className="column0 style57 null style60" colSpan="12">
                  <Field
                      label=""
                      name="comments"
                      accepter={Input}
                     
                    />
                  </td>
                </tr>
                <tr className="row37">
                  <td
                    className="column0 style62 null style64"
                    colSpan="12"
                  ></td>
                </tr>
                <tr className="row38">
                  <td
                    className="column0 style65 null style67"
                    colSpan="12"
                  ></td>
                </tr>
                <tr className="row39">
                  <td className="column0 style68 s style70" colSpan="12">
                    Receiving User SignOff
                  </td>
                </tr>
                <tr className="row40">
                  <td className="column0 style71 s style73" colSpan="12">
                    &quot;I assume inventory accountability for the above
                    described equipment.&quot;
                  </td>
                </tr>
                <tr className="row41">
                  <td className="column0 style9 null"></td>
                  <td className="column1 style10 null"></td>
                  <td className="column2 style10 null"></td>
                  <td className="column3 style10 null"></td>
                  <td className="column4 style10 null"></td>
                  <td className="column5 style10 null"></td>
                  <td className="column6 style10 null"></td>
                  <td className="column7 style10 null"></td>
                  <td className="column8 style12 null"></td>
                  <td className="column9 style10 null"></td>
                  <td className="column10 style10 null"></td>
                  <td className="column11 style11 null"></td>
                </tr>
                <tr className="row42">
                  <td className="column0 style9 s">Signed:</td>
                  <td className="column1 style74 null style74" colSpan="8"></td>
                  <td className="column9 style1 null"></td>
                  <td
                    className="column10 style74 null style75"
                    colSpan="2"
                  ></td>
                </tr>
                <tr className="row43">
                  <td className="column0 style9 null"></td>
                  <td className="column1 style76 s style77" colSpan="8">
                    Receiver
                  </td>
                  <td className="column9 style78 s style67" colSpan="3">
                    Date
                  </td>
                </tr>
                <tr className="row44">
                  <td className="column0 style14 null"></td>
                  <td className="column1 style1 null"></td>
                  <td className="column2 style1 null"></td>
                  <td className="column3 style1 null"></td>
                  <td className="column4 style1 null"></td>
                  <td className="column5 style1 null"></td>
                  <td className="column6 style1 null"></td>
                  <td className="column7 style1 null"></td>
                  <td className="column8 style3 null"></td>
                  <td className="column9 style1 null"></td>
                  <td className="column10 style1 null"></td>
                  <td className="column11 style13 null"></td>
                </tr>
                <tr className="row45">
                  <td className="column0 style15 null"></td>
                  <td className="column1 style2 null"></td>
                  <td className="column2 style2 null"></td>
                  <td className="column3 style2 null"></td>
                  <td className="column4 style2 null"></td>
                  <td className="column5 style2 null"></td>
                  <td className="column6 style2 null"></td>
                  <td className="column7 style2 null"></td>
                  <td className="column8 style4 null"></td>
                  <td className="column9 style2 null"></td>
                  <td className="column10 style2 null"></td>
                  <td className="column11 style16 null"></td>
                </tr>
                <tr className="row46">
                  <td className="column0 style68 s style85" colSpan="12">
                    Releasing Department SignOff
                  </td>
                </tr>
                <tr className="row47">
                  <td className="column0 style71 s style85" colSpan="12">
                    &quot;I relinquish inventory accountability for the above
                    described equipment.&quot;
                  </td>
                </tr>
                <tr className="row48">
                  <td className="column0 style9 null"></td>
                  <td className="column1 style10 null"></td>
                  <td className="column2 style10 null"></td>
                  <td className="column3 style10 null"></td>
                  <td className="column4 style10 null"></td>
                  <td className="column5 style10 null"></td>
                  <td className="column6 style10 null"></td>
                  <td className="column7 style10 null"></td>
                  <td className="column8 style12 null"></td>
                  <td className="column9 style10 null"></td>
                  <td className="column10 style10 null"></td>
                  <td className="column11 style11 null"></td>
                </tr>
                <tr className="row49">
                  <td className="column0 style9 s">Signed:</td>
                  <td className="column1 style74 null style74" colSpan="8"></td>
                  <td className="column9 style1 null"></td>
                  <td
                    className="column10 style74 null style75"
                    colSpan="2"
                  ></td>
                </tr>
                <tr className="row50">
                  <td className="column0 style9 null"></td>
                  <td className="column1 style76 s style77" colSpan="8">
                    IT Department
                  </td>
                  <td className="column9 style78 s style67" colSpan="3">
                    Date
                  </td>
                </tr>
                <tr className="row51">
                  <td className="column0 style9 null"></td>
                  <td className="column1 style10 null"></td>
                  <td className="column2 style10 null"></td>
                  <td className="column3 style10 null"></td>
                  <td className="column4 style10 null"></td>
                  <td className="column5 style10 null"></td>
                  <td className="column6 style10 null"></td>
                  <td className="column7 style10 null"></td>
                  <td className="column8 style12 null"></td>
                  <td className="column9 style10 null"></td>
                  <td className="column10 style10 null"></td>
                  <td className="column11 style11 null"></td>
                </tr>
                <tr className="row52">
                  <td className="column0 style79 null style81" colSpan="4"></td>
                  <td className="column4 style17 null"></td>
                  <td className="column5 style17 null"></td>
                  <td className="column6 style17 null"></td>
                  <td className="column7 style17 null"></td>
                  <td className="column8 style18 null"></td>
                  <td className="column9 style17 null"></td>
                  <td className="column10 style17 null"></td>
                  <td className="column11 style19 null"></td>
                </tr>
                <tr className="row53">
                  <td className="column0 style82 null style83" colSpan="4"></td>
                  <td className="column4 style20 null"></td>
                  <td className="column5 style20 null"></td>
                  <td className="column6 style20 null"></td>
                  <td className="column7 style20 null"></td>
                  <td className="column8 style21 null"></td>
                  <td className="column9 style20 null"></td>
                  <td className="column10 style20 null"></td>
                  <td className="column11 style22 null"></td>
                </tr>
              </tbody>
            </table>
          </Form>
        </div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col-md-6 right">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={Print}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Mainbody;
