import "./dashboard.css";
import SaidNavbar from "../../components/saidnavbar/saidnavbar";
import TopNavbar from "../../components/topnavbar/topnavbar";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import  { getBaseUrl } from '../../http';
const Dashboard =  () => {
  let obj={"new":0,"progress":0,"hold":0,"completed":0};
  let task={"plan":{},"equipment":{},"team":{}}
  const navigate = useNavigate();
  let [dashboardCount, setDashboard] = useState(obj);
  let [comments, setComments] = useState([task]);
  const apiBaseUrl=getBaseUrl();
  useEffect(() => {

   getDashboardCount();
   getData();
  }, []);
  const NavRequestDetails = (id) => {
    navigate('/plans_details/' + id, {replace: true});
  };
  const getDashboardCount = async () => {
    let json = await axios.get(apiBaseUrl+
      "/plan/plan_dashboard/0");
      setDashboard(json.data.data);

  };

  const getData = async() => {
    let request={
        "status": 1,
        "start": 0,
        "page_size": 4,
        "order_by": "tp.CreatedOn",
        "sort": "desc",
        "search": ""
      };    
    const res = await axios.post(apiBaseUrl+"/plan/planning_list", request);
        let i = 1;
        const datesort = res.data.data.sort((a, b) => Date.parse(b.plan.CreatedOn));
        const dt = datesort.map(obj => { return { ...obj, slno: i++ } });
       // comments=dt;
        setComments(dt);
        console.log(comments,'comments');
    
  };
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <SaidNavbar></SaidNavbar>
      <div className="flex-lg-1 h-screen overflow-y-lg-auto">
        <TopNavbar></TopNavbar>
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-3 pb-3">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <h1 className="h2 ls-tight">Dashboard</h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
         
        </main>
      </div>
    </div>
 );
};

export default Dashboard;
