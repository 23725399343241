import "./saidnavbar.css";
import axios from "axios";
import { useContext, useState } from "react";
import { NavLink } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import  { getBaseUrl } from '../../http';
const SaidNavbar = () => {
    const { loading, error, dispatch } = useContext(AuthContext);
    const { user } = useContext(AuthContext);
    const apiBaseUrl=getBaseUrl();
    const logout = async (e) => {   
        const res = await axios.post(apiBaseUrl+"/auth/logout");
        //localStorage.removeItem("user");
        dispatch({ type: "LOGOUT" });
      };

  return (
    <nav
      className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg scrollbar"
      id="navbarVertical"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler ms-n2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="/">
          <img src="../assets/img/logo/main_logo.png" alt="..." />
        </a>
        <div className="navbar-user d-lg-none">
          <div className="dropdown">
            <a
              href="#"
              id="sidebarAvatar"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="avatar-parent-child">
                <img
                  alt="Image Placeholder"
                  src="../assets/people/img-5.jpg"
                  className="avatar avatar- rounded-circle"
                />
                <span className="avatar-child avatar-badge bg-success"></span>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="sidebarAvatar"
            >
                <div className="dropdown-header">
                  <span className="d-block text-sm text-muted mb-1">
                    Signed in as
                  </span>{" "}
                  <span className="d-block text-heading font-semibold">
                  {!user? null : user.firstname} {" "} {!user? null : user.lastname}
                  </span>
                </div>
                <div className="dropdown-divider"></div>
                <NavLink className="dropdown-item" to="/">
                  <i className="bi bi-house me-3"></i>Home{" "}
                </NavLink>
                <NavLink className="dropdown-item" to="/profile/settings">
                <i className="bi bi-person me-3"></i>Profile
                </NavLink>
                <NavLink className="dropdown-item" to="/settings">
                  <i className="bi bi-gear me-3"></i>Settings
                </NavLink>
                <div className="dropdown-divider"></div>
                <NavLink className="dropdown-item" to="#" onClick={ () => logout()}>
                  <i className="bi bi-box-arrow-left me-3"></i>Logout
                </NavLink>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="sidebarCollapse">
          <ul className="navbar-nav">
            <li>
              <span className="nav-link text-xs font-semibold text-uppercase text-muted ls-wide">
                Menu
              </span>
            </li>
            <li className="nav-item">     
              <NavLink to='/dashboard' className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <i className="bi bi-grid-1x2"></i> Dashboard
              </NavLink>
            </li>
            {/* <li className="nav-item">     
              <NavLink to='/projects'       className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <i className="bi bi-journals"></i> Projects
              </NavLink>
            </li> */}             
            <li className="nav-item">     
              <NavLink to='/users' className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <i className="bi bi-hdd-stack"></i> Users
              </NavLink>
            </li>      
           
         
          </ul>          

          </div>
      </div>
    </nav>
  );
};

export default SaidNavbar;
