import "./mainbody.css";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Search from "../../../../components/table/search";
import Pagination from "../../../../components/table/pagination";
import { getBaseUrl, getToken } from "../../../../http";
import {

  Loader
} from "rsuite";
const apiBaseUrl = getBaseUrl();
const token = getToken();

const MainBody = () => {
  const [show, setShow] = useState(false);
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [assetLoader, setAssetLoader] = useState(false);
  const navigate = useNavigate();
  var i = 1;
  const ITEMS_PER_PAGE = 10;
  const data = [];

  const NavRequestDetails = (id) => {
    navigate("/checkout_details/" + id, { replace: true });
  };
  const getData = async () => {

    setAssetLoader(true);
    debugger;
   
  
    var config = {
      method: "get",
      url: apiBaseUrl + "users",
      headers: {
        Authorization: token,
       'content-type': 'application/json'
      },
    };
    const res = await axios(config);
   // console.log(res);
    //    const res = await axios.get(apiBaseUrl+"users", { token });
    let i = 1;
    const datesort = res.data.rows.sort((a, b) => b.id);
    const dt = datesort.map((obj) => {
      return { ...obj, slno: i++ };
    });
    setTotalItems(res.data.total);
    setComments(dt);
    setAssetLoader(false);
  //  console.log(dt, "dt");
  };

  const printDep = (obj) => {
    if(obj!=null)
    {
    return obj.name;
    }else{
        return '-';
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const commentsData = useMemo(() => {
    let computedComments = comments;
    //console.log(comments);
    if (search) {
        try{
      computedComments = computedComments.filter(
        (comment) =>
          comment.first_name?.toLowerCase().includes(search.toLowerCase()) ||
          comment.jobtitle?.toLowerCase().includes(search.toLowerCase()) ||
          comment.email?.toLowerCase().includes(search.toLowerCase()) ||
          comment.phone?.toLowerCase().includes(search.toLowerCase()) ||
          comment.username?.toLowerCase().includes(search.toLowerCase())||
          (comment.department!=null && comment.department.name?.toLowerCase().includes(search.toLowerCase()))||
          (comment.location!=null && comment.location.name?.toLowerCase().includes(search.toLowerCase()))||
          (comment.manager!=null && comment.manager.name?.toLowerCase().includes(search.toLowerCase()))||
        //   comment.assets_count.includes(search.toLowerCase())||
        //   comment.licenses_count.includes(search.toLowerCase())||
        //   comment.consumables_count.includes(search.toLowerCase())||
        //   comment.accessories_count.includes(search.toLowerCase())||
          comment.notes?.toLowerCase().includes(search.toLowerCase())||
          comment.groups?.toLowerCase().includes(search.toLowerCase())
      );
      }catch(e)
      {console.log(e)}
   
    }

    setTotalItems(computedComments.length);

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search]);
  const assetCheckOutLoader = () => {
    if (assetLoader) {
      return (
        <div>
          <Loader backdrop content="loading..." vertical />
        </div>
      );
    } else {
      return "";
    }
  };
  return (
   
    <main className="py-6 bg-surface-secondary">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="card">
              <div className="px-4 py-4 border-bottom d-flex flex-column flex-sm-row gap-3">
                <div className="d-flex gap-3">
                  <a
                    href="#offcanvasCreate"
                    data-bs-toggle="offcanvas"
                    className="btn btn-sm btn-square btn-neutral border-dashed shadow-none border-warning-hover"
                  >
                    <i className="bi bi-volume-up"></i>
                  </a>
                  <h1 className="h4 ls-tight py-2">All Users</h1>
                </div>
                {assetCheckOutLoader()}
                <div className="ms-auto hstack gap-2">
                  <div className="input-group input-group-sm input-group-inline">
                    <span className="input-group-text pe-2">
                      <i className="bi bi-search"></i>{" "}
                    </span>
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="table-responsive">
                  <table className="table table-hover table-nowrap">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Title</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Username</th>
                        <th scope="col">Department</th>
                        <th scope="col">Location</th>
                        <th scope="col">Manager</th>
                        <th scope="col">Assets</th>
                        <th scope="col">License</th>
                        <th scope="col">Consumables</th>
                        <th scope="col">Accessories</th>
                        <th scope="col">Notes</th>
                        <th scope="col">Groups</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {commentsData.map((comment, b) => (
                        <tr
                          key={b}
                          className="pointer"
                          
                        >
                          <th>{comment.first_name}</th>
                          <th>{comment.jobtitle}</th>
                          <td>{comment.email}</td>
                          <td>{comment.phone}</td>
                          <td>{comment.username}</td>
                          <td>{printDep(comment.department)}</td>
                          <td>{printDep(comment.location)}</td>
                          <td>{printDep(comment.manager)}</td>
                          <td>{comment.assets_count}</td>
                          <td>{comment.licenses_count}</td>
                          <td>{comment.consumables_count}</td>
                          <td>{comment.accessories_count}</td>
                          <td>{comment.notes}</td>
                          <td>{comment.groups}</td>
                         
                          <td>
                        <a href={"/checkout_details/"+comment.id} className="btn btn-sm btn-primary">Issue</a>
                        <span>|</span>
                        <a href={"/checkin_details/"+comment.id} className="btn btn-sm btn-primary">Return</a>
                          </td>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer border-0 py-5">
                  <div className="row align-items-center">
                    <div className="col-lg d-none d-lg-block">
                      <span className="text-muted text-sm">
                        Showing {commentsData.length} items out of {totalItems}{" "}
                        results found
                      </span>
                    </div>
                    <div className="col-lg-8">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainBody;
