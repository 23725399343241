import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import './App.css';
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import CheckInDetails from "./pages/check_in/check_in";

import Notfoundpage from "./pages/notfoundpage/notfoundpage";


import UserDetails from "./pages/user_details/user_details";
import Users from "./pages/users/users";


function App() {
  return (
    <BrowserRouter>
      <Routes>        
        <Route index element={<Login/>}/>
        <Route path="/" element={<Login/>}/>
         
        <Route path="/dashboard" element={<Dashboard/>}/>
    
        <Route path="/users" element={<Users/>}/>
        <Route path="/checkout_details/:id" element={<UserDetails/>}/>
        <Route path="/checkin_details/:id" element={<CheckInDetails/>}/>
        <Route path="*" element={<Notfoundpage />} />
      </Routes>
      <Toaster position="bottom-left" />
    </BrowserRouter>
    );
}

export default App;
