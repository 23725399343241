import React from 'react'
import SaidNavbar from "../../components/saidnavbar/saidnavbar";
import TopNavbar from "../../components/topnavbar/topnavbar";
import MainBody from "./components/mainbody/mainbody";

function CheckInDetails() {
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <SaidNavbar></SaidNavbar>
      <div className="flex-lg-1 h-screen overflow-y-lg-auto">
        <TopNavbar></TopNavbar>
        <MainBody></MainBody>
      </div>
    </div>
  )
}

export default CheckInDetails