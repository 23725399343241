// http.js

export function getBaseUrl() {
    let url;
    let env='production';
   // env='development';
    console.log(env,'env');
    switch(env) {
      case 'production':
        url = 'https://itasset.app-brisigns.com/api/v1/';
        break;
        case 'uat':
        url = 'http://snipe-it/api/v1/';
        break;
      case 'development':
      default:
        url = 'http://snipe-it/api/v1/';
    }
  
    return url;
  }

  export function getToken(){
   //return 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiM2EyYjVmZDViYmE4NDMzMTg2NzNlMjhiNmUzZDE4OGM1OTg5OGQ2N2JjNDgyYjg2MDIzMmM0MTBjM2U1ZGU0NzEwZmRmODlkZjIzYjhkN2EiLCJpYXQiOjE2ODYxMTc0OTkuNTEzMDM1LCJuYmYiOjE2ODYxMTc0OTkuNTEzMDM4LCJleHAiOjIxNTk1MDMwOTkuNDg1NTExLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.NSvWlKpGVAua3_TNtkc_aDw-Joh2tdVSTxddGUJEXly508RoThTDHz5w2JPXvSmGGQIyCmAtVW8NXWEAunkrV88nw6a_kJfzvVrLjCa4VLTJ9mFIWjgAxn65-gQUXvxXdSHKiw_7_MINgUL5bm5jt8kzsKTbH4ClZBTQaojUZ6cG0LtBwB6405BzsqAm9G8gfy3N2u34RTTm0tC8f9aaSZBYBMPp3Y8fJXWgfXPZU0vdd8oBeWP58IXlH-NeVcB7uP-Q7liT2x2VGgH4dxj2V1qJ_F-8_tyK0wl15nKHQiLYGZ9SR8AQ5G2tnKetIe6lEG2lSubUNLoMW2jvJNJBshX5naGhgtLGy6URLWIWAG1VTcKJDl0fHBMFGu1c71PBCFVPRJUHIg4ghWKmE_ZsGGzauA5gQN_ua0QBGqobEYy42XPiQh3ZCI133Wn4AZqBy6orxvfE4sm-r4dm7nyOpnm61TldEXOaVZt59xt8JVCilRcumGzQJLsIa2O1QcqlGnIq5Wmr4-qutIkpaiecVVrQPRzTvnx5NBzxoIx0p2pLG_sIZoUMrKNcD3_1XQc4GAFsuGH5HNkPKIFFesHsUgaXOUsT4B7aafYjjfFzFjcRB_0zkjaz_VCMdANK3yrGyQ3ZfqVplnHsmJCT_FwcVHH_45TcnuwJhfYOqMY9Ng8';
   return 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODE3Y2QwODc3OGQ0YmNhMmQxOTU4MjQyNWZhOGQwMWQ1NmFjOGYzMWY5ODY3MWUwOTE0ZDI1ZWFhNjliMTRjMjM2MThhOWQ2ZmFmMjI2ODkiLCJpYXQiOjE2ODczNTAzNzkuNjQ0NTQ1LCJuYmYiOjE2ODczNTAzNzkuNjQ0NTU2LCJleHAiOjIxNjA3MzU5NzkuNDgyNDkxLCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.rpnJlbk85EfN7TSNBfrHultwXTc0U1xLW7ZZ0UzPNK4YAOV_5cS8TIq7SVk4uCCWmQM_6Z55v4uFvKk4VcFWnNp5RipnwmmUEdQm4OLmu-ejffCOP0H2_juF4uLyYYGO4ODcz4fR30zc61t6mMs2-zS5sKWsb2q_21kc6To2Ol_686Pdh_EfzuFZ-yl8HMDGovsB9td7fUe51HGr2GYhU6aGM7TQ7q7CoOMHeEjpfLfxtORI1OrhrHjz8YaMOfeKTFXBfhldomxoC0fYVwi-QKTAmSPOfJ-3uPb2KEs8z8PzHAAmpys6CJ11L-U8hPj-9nnO7C8RLp3RIRlk0g076AA6QQc3bdnF9UouTE2TM6jIWgp-YU6GixGuWZi0T-jCu0xGTKhKcglqYo4v3SG1HFrpR4B6N9DC2bbWacdcVQk9UgNK7lheE_kdKusjaQxrMbEIXYFyMEyWti1kW2cmH2KrLUmRF6t22mKC8LImjDuUFyT1P4WWnnECXLjUI1mjDhjxb4JbzeHJWEYDlWcCb1dG7G0ugnPjbZVhGk33wh43MxqehaKluJl9JysX9djZ43IjGoq6SgYAHfJx8kEjOAZVNYuj06bLsz85SpE49AXhcstUPqpBJpLalqsSfScYzZ2psdz2P6NVetIAdiHsF6AzP2eG3glk-aoBusQrX8A';
   
  }
  
  

