import  { getBaseUrl } from '../../http';
import axios from "axios";
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom';
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import { browserName,BrowserView, MobileView, isBrowser, isMobil,osName,deviceType } from 'react-device-detect';
import "./login.css";

const Login = () => {
  const { user, loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiBaseUrl=getBaseUrl();
  const [isDisabled, setDisabled] = useState(false);
 
  const handleClick = async (e) => {
    setDisabled(true);
    dispatch({ type: "LOGIN_START" });
   ;
    try {
     // const resIp = await axios.get('https://geolocation-db.com/json/')
     //console.log(resIp.data);
    
      
      //console.log(deviceType,'BrowserDetection')

      let request={
        "userName": e.username,
        "password": e.password,
        "device": deviceType,
        "plat_form": osName,
        "user_agent": browserName,
        "imei": "",
        "ipv4":"" //resIp.data.ipv4
      }
     // console.log(getBaseUrl(),'getBaseUrl');
     const res = await axios.post(apiBaseUrl+"/auth/login",request);
     // console.log(res.request,res.config,res.headers,'check')
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });     
      Cookies.set('access_token', res.data.token);
      navigate("/dashboard");
    } catch (err) {
      //console.log(res.request,res.config,res.headers,'check')
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
      setDisabled(false);
    }
  };

  const initialValues = { username: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
  
    e.preventDefault();
    if(validate(formValues) && formValues.username=='BRIAsset' && formValues.password=='ItAsset@2023')
    {
    navigate("/users");
    setIsSubmit(true);
    }else{
      toast.error("Invalid user details.", { duration: 6000 });
      setFormErrors(validate(formValues));
      setIsSubmit(false);  
    }
    
    
   
  };

  useEffect(() => {
    //console.log(formErrors.username);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //console.log(formValues);
      handleClick(formValues);
    }
    let cok = Cookies.get('access_token');
    console.log(cok);
    if (user != null && cok != undefined) {
      navigate("/dashboard");
      console.log(user);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };


  return (
    <div className="px-5 py-5 h-screen bg-surface-secondary d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center">
        <div className="col-11 col-md-6 col-lg-4 min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-10 position-relative">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <div className="col-11 col-lg-4 col-md-12 col-xl-12 mx-auto">
                  <div className="text-center mb-8">
                    <a className="d-inline-block mt-2" href="#">
                      <img
                        src="../assets/img/logo/BR-small-01-.png"
                        className="h-12"
                        alt="BRI Logo"
                      />
                    </a>
                    <h2 className="ls-tight font-bolder mt-4">Login</h2>
                    <p className="mt-2 font-bolder">Welcome to BRI Asset</p>
                  </div>
                  <form onSubmit={handleSubmit}>

                  {error ? <div className="alert alert-danger alert-dismissible fade show mb-2"
                      role="alert">
                        {error.message ? error.message : 'Please check your internet connection and try again!' }
                      </div> : (null)}
                    
                    <div className="mb-3">
                      <label className="form-label">Username</label>
                      <input
                        type="text"
                        className={formErrors.username ? "form-control error" : "form-control"}
                        name="username"
                        value={formValues.username}
                        onChange={handleChange}
                        placeholder="Your Username"
                        autoComplete="username"
                      />
                      <div className="invalid-feedback">
                        {formErrors.username}
                      </div>
                    </div>
                    <div className="mb-5">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className={formErrors.password ? "form-control error" : "form-control"}
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      <div className="invalid-feedback">
                        {formErrors.password}
                      </div>
                    </div>
                    <div className="mb-5">
                      <div className="form-check">
                        {/* <input
                          className="form-check-input"
                          type="checkbox"
                          name="check_example"
                          id="check_example"
                        />
                        <label className="form-check-label">
                          Keep me logged in
                        </label> */}
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-full" disabled={isDisabled}>
                        Sign in
                      </button>
                    </div>
                  </form>
                  <div className="my-6">
                    {/* <small>Forgot password?</small>
                    <NavLink to='/passwordreset' className="text-warning text-sm font-semibold">
                     {" "} Reset
                    </NavLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
