import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Notfoundpage = () => {
  return (
    <div className="px-5 py-5 h-screen bg-surface-secondary d-flex flex-column justify-content-center">
      <div className="row justify-content-center align-items-sm-center">
        <div className="col-9 col-sm-6 col-lg-4">
          <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
            <img
              className="img-fluid"
              src="../../assets/img/oc-thinking.svg"
              alt="Image Description"
              data-hs-theme-appearance="default"
            />
          </div>
        </div>

        <div className="col-sm-6 col-lg-4 text-center text-sm-start">
          <h1 className="lh-tight ls-tighter display-1 mb-4">Ooops!</h1>
          <p className="lead lh-relaxed mb-4">
            The page you are looking for could not be found.
          </p>
          <div className="my-10">
            <NavLink className="btn btn-dark" to="../">
              Return Home
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notfoundpage;
